import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cn } from "src/lib/utils";

type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> & {
  labelText?: React.ReactNode;
  helperText?: string;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  defaultChecked?: boolean;
};

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      className,
      labelText,
      helperText,
      disabled,
      defaultChecked = false,
      ...props
    },
    ref
  ) => {
    const [isChecked, setIsChecked] = React.useState(defaultChecked);

    return (
      <label // Wrap the entire component in a label
      className={cn(
        "relative rounded w-full flex flex-row items-start justify-start gap-[8px] text-left text-sm text-slate-600 font-text-sm-font-normal py-2",
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        className
      )}
      >
        <CheckboxPrimitive.Root
          ref={ref}
          checked={isChecked}
          onCheckedChange={() => {}}
          className={cn(
            "relative rounded box-border min-w-[19px] h-[19px] border-[1px] border-solid border-slate-300 bg-slate-50",
            isChecked
              ? "border-fuchsia-600 bg-fuchsia-600"
              : "border-slate-300 bg-slate-50"
          )}
          onClick={() => {
            const newChecked = !isChecked;
            setIsChecked(newChecked);
            props.onCheckedChange && props.onCheckedChange(newChecked);
          }}
          {...props}
          disabled={disabled}
        >
          <CheckboxPrimitive.Indicator
            className={cn(
              "flex items-center justify-center p-0 m-0",
              isChecked ? "bg-fuchsia-600" : "bg-slate-50"
            )}
          >
            <Check className={isChecked ? "h-4 w-4 text-white" : "h-4 w-4"} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        <div className="flex flex-col items-start justify-start gap-[2px]">
          <div className="relative leading-[125%] font-medium xs:text-xs">
            {labelText}
          </div>
          <div className="relative leading-[150%] text-slate-500">
            {helperText}
          </div>
        </div>
      </label>
    );
  }
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
