import React from "react";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";
import { useTranslation } from "../../TranslationContext";

const PaymentPolicy = () => {
  const translations = useTranslation();

  return (
    <div>
      <PageHeader title={translations.payment_policy.title} />
      <TextContainer>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section1_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section1_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section2_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section2_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section3_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section3_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section4_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section4_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section5_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section5_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section6_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section6_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.payment_policy.section7_title}
        </p>
        <p className="m-0">
          {translations.payment_policy.section7_content}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">
          {translations.payment_policy.contact_us}
        </p>
      </TextContainer>
    </div>
  );
};

export default PaymentPolicy;