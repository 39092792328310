import React from "react";

type TableCellData = {
  value: string;
  unit: string;
};

type TableRowProps = {
  columns: number;
  data: TableCellData[];
};

const TableCell: React.FC<TableCellData & { hideUnitOnXs?: boolean }> = ({
  value,
  unit,
  hideUnitOnXs,
}) => {
  return (
    <div className="self-stretch flex-1 bg-white flex flex-col p-2.5 items-center justify-center border-[1px] border-solid border-indigo-200">
      <div className="flex flex-row items-start justify-start">
        <div className="relative leading-[162.5%] font-regular mr-1">
          {value}
        </div>
        {hideUnitOnXs ? (
          <div className="relative leading-[162.5%] font-regular xs:hidden text-slate-500">
            {unit}
          </div>
        ) : (
          <div className="relative leading-[162.5%] font-regular text-slate-500">
            {unit}
          </div>
        )}
      </div>
    </div>
  );
};

const TableRow: React.FC<TableRowProps> = ({ columns, data }) => {
  if (columns !== data.length) {
    console.error("Mismatch between columns and data length");
    return null;
  }

  return (
    <div className="self-stretch h-[52px] flex flex-row items-start justify-start">
      {data.map((item, index) => (
        <TableCell
          key={index}
          value={item.value}
          unit={item.unit}
          hideUnitOnXs={index === 0} // Hide unit only for the first column
        />
      ))}
    </div>
  );
};

export default TableRow;
