"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

import { ChevronDown } from "lucide-react";

import { cn } from "src/lib/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      "border-b text-slate-950 fontFamily-outfit text-lg font-medium",
      className
    )}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

type AccordionTriggerProps = React.ComponentPropsWithoutRef<
  typeof AccordionPrimitive.Trigger
> & {
  variant?: string;
  icon?: React.ComponentType<{ className?: string }>;
};

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(
  (
    { className, children, variant, icon: Icon = null, ...props }, // Set default Icon to null
    ref
  ) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isRotated, setIsRotated] = React.useState(false); // Add this line

    return (
      <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
          ref={ref}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsRotated(!isRotated)} // Add this line
          className={cn(
            "flex w-full items-center justify-between py-4 transition-all text-left hover:no-underline hover:font-bold",
            variant === "bold" ? "font-medium" : "font-regular",
            className
          )}
          {...props}
        >
          <div className="flex items-center pr-4">
            {Icon && (
              <div className="pr-4">
                <Icon className="w-8 h-8" />
              </div>
            )}{" "}
            {/* Only render Icon if it's not null and add padding right */}
            {children}
          </div>
          <div
            className={`p-2 inline-block rounded-md ${
              isHovered ? "bg-light-gradient" : ""
            }`}
          >
            <ChevronDown
              className={`h-4 w-4 shrink-0 transition-transform duration-200 ${
                isRotated ? "rotate-180" : ""
              }`}
            />
          </div>
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
    );
  }
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "overflow-hidden w-full font-normal leading-6 text-left text-base transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className
    )}
    {...props}
  >
    <div className="pb-4 pt-0 space-y-2">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
