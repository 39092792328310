import { FunctionComponent } from "react";

import bike from "../../assets/svg/bike.svg";
import campaign from "../../assets/svg/campaign.svg";
import group from "../../assets/svg/group.svg";
import headphones from "../../assets/svg/headphones.svg";
import heart_check from "../../assets/svg/heart_check.svg";
import school from "../../assets/svg/school.svg";
import { useTranslation } from "../../TranslationContext";

const Services: FunctionComponent = () => {
  const translations = useTranslation();

  return (
    <div
      id="services"
      className="relative bg-slate-950 w-full flex flex-col p-24 pb-0 box-border items-center justify-start gap-14 text-center text-4xl text-white font-text-sm-font-medium lg:px-6 md:py-10 xs:px-4 md:pb-4 xs:text-3xl"
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-[24px]">
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
          <div className="self-stretch relative leading-[150%] font-extrabold">
            <h1>{translations.services.title}</h1>
          </div>
          <div className="self-stretch relative text-xl leading-[150%] text-slate-200 lg:px-16 md:px-4 sm:px-0 xs:text-base">
            {translations.services.subtitle}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-between gap-4 max-w-[1100px] text-xl text-slate-200 md:flex-col md:gap-10 xs:gap-6 lg:text-lg xs:text-sm">
        <div className="w-full flex-1 flex flex-row items-center justify-between gap-2 lg:gap-0">
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.sportAlt}
              src={bike}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">
                {translations.services.sport}
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.schoolAlt}
              src={school}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">
                {translations.services.school}
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.politicalAlt}
              src={campaign}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">
                {translations.services.political}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex-1 flex flex-row items-center justify-between gap-2 xs:gap-0">
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.conferenceAlt}
              src={group}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">
                {translations.services.conference}
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.weddingAlt}
              src={heart_check}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">
                {translations.services.wedding}
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.groupAlt}
              src={headphones}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">
                {translations.services.group}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
