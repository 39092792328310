import { Link as RouterLink, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { Button } from "../ui/button";
import email_sent from "../../assets/svg/email_sent.svg";
import card_fail from "../../assets/svg/card_fail.svg";
import info_outline from "../../assets/svg/info_outline.svg";
import { useTranslation } from "../../TranslationContext";
import Cookies from "js-cookie";

declare global {
  interface Window {
    gtag_report_conversion: (url?: string) => void;
  }
}

const OrderConfirmation = () => {
  const translations = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get("RES"); // 'OK' for successful payment
  const amount = queryParams.get("AMT");
  const currency = queryParams.get("CURR");
  const orderNumber = queryParams.get("VS");
  const acquirerCode = queryParams.get("AC");
  const transactionId = queryParams.get("TID");
  const timestamp = queryParams.get("TIMESTAMP");
  const hmac = queryParams.get("HMAC");
  const ecdsaKey = queryParams.get("ECDSA_KEY");
  const ecdsa = queryParams.get("ECDSA");

  // Determine cardpayKey based on hostname
  const hostname = window.location.hostname;
  let cardpayKey = "";

  switch (hostname) {
    case "dtfprint.sk":
      cardpayKey = "DTFSK";
      break;
    case "dtfprint.cz":
      cardpayKey = "DTFCZ";
      break;
    case "dtfprint.at":
      cardpayKey = "DTFAT";
      break;
    case "dtf.nexprim.com":
      cardpayKey = "DTFCOM";
      break;
    case "odznaky.nexprim.sk":
      cardpayKey = "BADGESK";
      break;
    case "badges.nexprim.com":
      cardpayKey = "BADGECOM";
      break;
    default:
      cardpayKey = "UNKNOWN"; // Default value if hostname doesn't match
  }

  const languageMap: { [key: string]: string } = {
    SK: "sk",
    CZ: "cz",
    DE: "de",
    EN: "en",
    default: "sk",
  };

  const getLanguageFromCookies = (): string => {
    // Check for language cookie first
    const langCookie = Cookies.get("lang");
    if (langCookie && languageMap[langCookie]) {
      return languageMap[langCookie];
    }

    return languageMap["default"];
  };

  // Constructing the payload object with the extracted parameters
  const payload = {
    language: getLanguageFromCookies(),
    emailTitleConfirmation:
      translations.orderConfirmationPage.emailTitleConfirmation,
    emailTitleFailed: translations.orderConfirmationPage.emailTitleFailed,
    paymentStatus,
    amount,
    currency,
    orderNumber,
    acquirerCode,
    transactionId,
    timestamp,
    hmac,
    ecdsaKey,
    ecdsa,
    cardpayKey, // Add cardpayKey to the payload
  };

  const sendPaymentConfirmation = async () => {
    console.log(payload);

    const url =
      "https://europe-central2-nexprim-5569f.cloudfunctions.net/cardpay_payment_confirmation";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response data:", data);
      // Handle the response data as needed
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  // Call sendPaymentConfirmation function when the component mounts or based on specific conditions
  //tato ficura s delayom je dolezita, nemazat delay, komponent sa 2x mountuje
  useEffect(() => {
    let isMounted = true; // Flag to track component's mount status

    const timer = setTimeout(() => {
      if (isMounted) {
        sendPaymentConfirmation();
      }
    }, 500); // Delay the request by 500ms

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
      clearTimeout(timer); // Cleanup function to cancel the timeout
    };
  }, []);

  // Function to determine the message based on payment status
  const getPaymentStatusMessage = () => {
    return paymentStatus === "OK"
      ? translations.orderConfirmationPage.successMessage
      : translations.orderConfirmationPage.failureMessage;
  };

  // Function to determine the message based on payment status
  const getPaymentStatusSecondMessage = () => {
    return paymentStatus === "OK"
      ? translations.orderConfirmationPage.successDetails
      : translations.orderConfirmationPage.failureDetails;
  };

  return (
    <div
      className="font-outfit flex flex-col items-center justify-center "
      style={{ minHeight: "calc(100vh - 554px - 122px)" }} // Adjust the values based on actual heights
    >
      <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10 items-center justify-center bg-white text-center">
        <div className="flex flex-col items-center justify-start gap-16 sm:gap-10 py-20">
          <div className="w-full flex flex-col gap-10 items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-[10px] max-w-4xl">
              <div className="relative leading-[150%] font-semibold">
                {getPaymentStatusMessage()}
              </div>
              <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                {getPaymentStatusSecondMessage()}
              </div>
            </div>
            <div className="w-auto relative rounded-lg bg-light-gradient flex flex-row items-center justify-center px-6 py-5 box-border gap-4 text-left text-lg sm:text-base text-slate-900 font-normal">
              <img
                className="w-8 relative h-8 overflow-hidden shrink-0"
                alt="nexprim-info"
                src={info_outline}
              />
              <div className="flex-1 relative leading-[150%] whitespace-pre-wrap">
                {translations.orderConfirmationPage.emailNotice.text}
                <span className="font-semibold text-gradient">
                  {translations.orderConfirmationPage.emailNotice.highlight}
                </span>
                {translations.orderConfirmationPage.emailNotice.textAfter}
              </div>
            </div>
            <img
              className="relative w-60 h-60"
              alt={paymentStatus === "OK" ? "nexprim-success" : "nexprim-fail"}
              src={paymentStatus === "OK" ? email_sent : card_fail}
            />
            <RouterLink to="/">
              <Button
                variant="primary"
                size="default"
                className="uppercase"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {translations.orderConfirmationPage.homeButton}
              </Button>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
