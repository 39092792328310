import { FunctionComponent } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from "../../TranslationContext";

import delivery from "../../assets/svg/delivery.svg";
import fast from "../../assets/svg/fast.svg";
import price_tag from "../../assets/svg/price_tag.svg";
import stars from "../../assets/svg/stars.svg";
import magnet_badges_1 from "../../assets/webp/magnet_badges_1.webp";
import magnet_badges_2 from "../../assets/webp/magnet_badges_2.webp";
import magnet_badges_3 from "../../assets/webp/magnet_badges_3.webp";
import { Button } from "../ui/button";

const Features: FunctionComponent = () => {
  const translations = useTranslation();

  return (
    <div
      id="features"
      className="relative bg-white w-full overflow-hidden flex flex-col box-border items-center justify-start text-center text-xl text-slate-900 font-text-base-font-normal"
    >
      <div className="grid grid-cols-4 lg:grid-cols-2 xs:grid-cols-1 items-start justify-start gap-10 max-w-[1400px] py-14 px-24 lg:px-16 sm:px-10 xs:px-8">
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img
            className="relative w-16 h-16"
            alt={translations.features.price_tag_alt}
            src={price_tag}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.price}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.price_description}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img
            className="relative w-16 h-16"
            alt={translations.features.quality_alt}
            src={stars}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.quality}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.quality_description}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img
            className="relative w-16 h-16"
            alt={translations.features.fast_alt}
            src={fast}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.fast_processing}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.fast_processing_description}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img
            className="relative w-16 h-16"
            alt={translations.features.delivery_alt}
            src={delivery}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.fast_delivery}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.fast_delivery_description}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-slate-950 overflow-hidden flex flex-row items-start justify-center py-24 md:py-16 px-24 lg:px-16 sm:px-10 xs:px-8 box-border text-left sm:text-center text-4xl text-white font-text-xl-font-normal sm:text-3xl">
        <div className="flex flex-row xl:flex-col items-center justify-center xl:items-start gap-10 max-w-[1400px]">
          <div className="max-w-[500px] xl:max-w-[750px] flex flex-col xl:flex-row md:flex-col items-start justify-start xl:items-center md:items-start sm:items-center gap-10">
            <div className="self-stretch flex flex-col items-center justify-start gap-4">
              <div className="self-stretch relative leading-[150%] font-extrabold">
                {translations.features.magnetic_badges_title}
              </div>
              <div className="self-stretch relative text-xl sm:text-base leading-[150%] text-slate-200">
                {translations.features.magnetic_badges_description}
              </div>
            </div>
            <ScrollLink to="pricing" smooth={true} duration={500} offset={-88}>
              <Button
                variant="default"
                size="xl"
                className="uppercase w-[180px]"
                onClick={() => window.scrollTo(0, 0)}
              >
                {translations.features.check_price}
              </Button>
            </ScrollLink>
          </div>
          <div className="flex flex-col items-start justify-start w-full">
            <div className="self-stretch flex flex-row sm:flex-col items-start sm:items-center justify-start sm:justify-center gap-[20px] w-full">
              <div className="flex flex-row items-start justify-start max-w-[241px] sm:max-w-[320px] w-full">
                <img
                  src={magnet_badges_1}
                  alt={translations.features.magnetic_badges_alt}
                  className="self-stretch max-w-[241px] sm:max-w-[320px] w-full"
                />
              </div>
              <div className="flex flex-row items-start justify-start max-w-[241px] sm:max-w-[320px] w-full">
                <img
                  src={magnet_badges_2}
                  alt={translations.features.magnetic_badges_alt}
                  className="self-stretch max-w-[241px] sm:max-w-[320px] w-full"
                />
              </div>
              <div className="flex flex-row items-start justify-start max-w-[241px] sm:max-w-[320px] w-full">
                <img
                  src={magnet_badges_3}
                  alt={translations.features.magnetic_badges_alt}
                  className="self-stretch max-w-[241px] sm:max-w-[320px] w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;