import { FunctionComponent } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from "../../TranslationContext";

import badge_shiny from "../../assets/png/badge_shiny.png";
import badge_matte from "../../assets/png/badge_matte.png";
import check from "../../assets/svg/check.svg";
import close from "../../assets/svg/close.svg";

const Comparison: FunctionComponent = () => {
  const translations = useTranslation();

  return (
    <div
      id="comparison"
      className="relative bg-white w-full overflow-hidden flex flex-col box-border items-center justify-start text-center text-xl text-slate-900 font-text-base-font-normal"
    >
      <div className="w-full relative bg-slate-950 flex flex-col items-center justify-center pt-8 px-10 sm:px-4 pb-20 box-border gap-[40px_0px] text-center text-4xl sm:text-3xl text-white">
        <div className="w-full flex flex-col items-center justify-start gap-[16px_0px] max-w-[800px]">
          <b className="self-stretch relative leading-[150%]">
            {translations.comparison.title}
          </b>
          <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-300">
            {translations.comparison.description}
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-center max-w-[1440px] text-left text-base">
          <div className="flex flex flex-row md:flex-col items-start justify-center gap-10">
            <div className="flex flex-row xl:flex-col md:flex-row sm:flex-col w-full max-w-[700px] xs:max-w-[300px] rounded-2xl bg-slate-900 overflow-hidden items-center justify-center p-8 gap-6 sm:gap-4">
              <div className="flex flex-col items-center justify-center rounded-xl w-fit max-w-[170px]">
                <img
                  src={badge_shiny}
                  alt="Nexprim - Odznaky s vlastnou potlačou"
                  className="self-stretch w-full h-auto rounded-xl object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-start gap-2 sm:gap-1 w-full xs:max-w-[270px] xs:w-full">
                <div className="self-stretch flex flex-row items-center justify-start gap-4 text-3xl sm:text-2xl mb-2">
                  <div className="flex-1 relative leading-[150%] font-semibold">
                    {translations.comparison.shiny}
                  </div>
                  <div className="w-[84px] relative text-lg leading-[150%] text-slate-300 hidden">
                    +0,02€/ks
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-2">
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 mt-1"
                    alt=""
                    src={check}
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-1 text-lg sm:text-base">
                    <div className="w-full relative leading-[150%] font-medium inline-block">
                      {translations.comparison.durability}
                    </div>
                    <div className="self-stretch relative leading-[150%] font-light text-slate-300 text-base sm:text-sm h-[48px] md:h-auto">
                      {translations.comparison.shiny_durability}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-2">
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 mt-1"
                    alt=""
                    src={check}
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-1 text-lg sm:text-base">
                    <div className="w-full relative leading-[150%] font-medium inline-block">
                      {translations.comparison.price}
                    </div>
                    <div className="self-stretch relative leading-[150%] font-light text-slate-300 text-base sm:text-sm h-[48px] md:h-auto">
                      {translations.comparison.shiny_price}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-2">
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 mt-1"
                    alt=""
                    src={close}
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-1 text-lg sm:text-base">
                    <div className="w-full relative leading-[150%] font-medium inline-block">
                      {translations.comparison.commonness}
                    </div>
                    <div className="self-stretch relative leading-[150%] font-light text-slate-300 text-base sm:text-sm h-[48px] md:h-auto">
                      {translations.comparison.shiny_commonness}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row xl:flex-col md:flex-row sm:flex-col w-full max-w-[700px] xs:max-w-[300px] rounded-2xl bg-slate-900 overflow-hidden items-center justify-center p-8 gap-6 sm:gap-4">
              <div className="flex flex-col items-center justify-center rounded-xl w-fit max-w-[170px]">
                <img
                  src={badge_matte}
                  alt="Nexprim - Odznaky s vlastnou potlačou"
                  className="self-stretch w-full h-auto rounded-xl object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-start gap-2 sm:gap-1 w-full xs:max-w-[270px] xs:w-full">
                <div className="self-stretch flex flex-row items-center justify-start gap-4 text-3xl sm:text-2xl mb-2">
                  <div className="flex-1 relative leading-[150%] font-semibold">
                    {translations.comparison.matte}
                  </div>
                  <div className="w-[84px] relative text-lg leading-[150%] text-slate-300">
                    +0,02€/ks
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-2">
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 mt-1"
                    alt=""
                    src={check}
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-1 text-lg sm:text-base">
                    <div className="w-full relative leading-[150%] font-medium inline-block">
                      {translations.comparison.elegance}
                    </div>
                    <div className="self-stretch relative leading-[150%] font-light text-slate-300 text-base sm:text-sm h-[48px] md:h-auto">
                      {translations.comparison.matte_elegance}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-2">
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 mt-1"
                    alt=""
                    src={check}
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-1 text-lg sm:text-base">
                    <div className="w-full relative leading-[150%] font-medium inline-block">
                      {translations.comparison.originality}
                    </div>
                    <div className="self-stretch relative leading-[150%] font-light text-slate-300 text-base sm:text-sm h-[48px] md:h-auto">
                      {translations.comparison.matte_originality}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-2">
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 mt-1"
                    alt=""
                    src={close}
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-1 text-lg sm:text-base">
                    <div className="w-full relative leading-[150%] font-medium inline-block">
                      {translations.comparison.durability}
                    </div>
                    <div className="self-stretch relative leading-[150%] font-light text-slate-300 text-base sm:text-sm h-[48px] md:h-auto">
                      {translations.comparison.matte_durability}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comparison;