import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "src/lib/utils";

const buttonVariants = cva(
  "inline-flex whitespace-nowrap items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-all duration-300 focus-visible:ring-offset-blue-200 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary-gradient-to-r text-primary-foreground hover:bg-primary-gradient-to-l",
        primary:
          "text-fuchsia-500 border-2 border-fuchsia-500 hover:bg-fuchsia-500 hover:text-white focus-visible:bg-primary-gradient-to-r focus-visible:text-white focus-visible:ring-offset-fuchsia-200",
        primary_dark:
          "bg-gray-800 text-white hover:bg-gray-700 focus-visible:ring-offset-gray-200",
        ghost:
          "border-gradient-bottom text-slate-800 hover:bg-white hover:text-slate-950 focus-visible:bg-blue-50 focus-visible:text-green-700 focus-visible:ring-offset-gray-200",
        green:
          "bg-green-700 text-white hover:bg-green-800 focus-visible:bg-green-800 focus-visible:ring-offset-green-200",
        red: "bg-red-700 text-white hover:bg-red-800 focus-visible:bg-red-800 focus-visible:ring-offset-red-200",
        alternative:
          "text-gray-800 bg-white border-2 border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus-visible:bg-gray-100 focus-visible:text-gray-800 focus-visible:ring-offset-gray-200",
        alternative_dark:
          "text-white bg-slate-800 border-2 border-slate-800 hover:bg-gray-800 hover:text-white focus-visible:bg-gray-700 focus-visible:text-white focus-visible:ring-offset-gray-700",
        alternative_consent:
          "text-slate-300 bg-slate-800 hover:bg-slate-700 hover:text-white focus-visible:bg-slate-700",
        invisible: "text-white bg-white",
        outline:
          "border border-fuchsia-500 text-slate-800 hover:bg-white hover:text-slate-950 focus-visible:bg-blue-50 focus-visible:text-green-700 focus-visible:ring-offset-gray-200",
        outline_ghost:
          "border border-slate-200 bg-slate-50 text-slate-500 hover:bg-white hover:text-slate-950 focus-visible:bg-blue-50 focus-visible:text-green-700 focus-visible:ring-offset-gray-200 font-normal",

        link: "text-primary underline-offset-4 hover:underline",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",

        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      },
      size: {
        default: "h-12 py-2.5 px-4 text-base",
        xs: "h-10 rounded-md py-2 px-3 text-xs",
        sm: "h-12 rounded-md py-2 px-3 text-sm",
        lg: "h-14 rounded-md py-3 px-5 text-base",
        xl: "h-14 rounded-md py-3.5 px-6 text-base",
        default_stretch: "h-12 py-2.5 px-4 text-base sm:w-full",
        icon: "h-10 w-10",
        date_picker: "h-10 rounded-md py-0 px-2 text-sm",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
