import { FunctionComponent } from "react";

// Define the prop types
interface TestimonialProps {
  header: string;
  content: string;
  name: string;
}

// Update the component definition to accept the props
const Testimonial: FunctionComponent<TestimonialProps> = ({ header, content, name }) => {
  return (
    <div className="relative rounded-rounded-lg bg-slate-50 w-full flex flex-col p-[1.5rem] box-border items-start justify-start gap-[1.25rem] text-left text-[1.13rem] text-slate-900 font-leading-tight-text-sm-font-normal">
      <div className="self-stretch flex flex-col items-start justify-start gap-[0.75rem]">
        <div className="self-stretch relative leading-[125%] font-semibold">
          {header} {/* Use header prop */}
        </div>
        <div className="self-stretch flex flex-col items-start justify-start text-[0.88rem] text-slate-500">
          <div className="self-stretch relative leading-[150%] font-medium">
            {content} {/* Use content prop */}
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-end text-right text-[0.88rem] text-slate-500">
        <div className="flex-1 flex flex-col items-end justify-start">
          <div className="self-stretch relative leading-[125%]">{name}</div> {/* Use name prop */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
