import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "../../TranslationContext";

const Metrics: FunctionComponent = () => {
  const [badges, setBadges] = useState(100000);
  const [experience, setExperience] = useState(5);
  const translations = useTranslation();

  useEffect(() => {
    const startBadges = new Date(2024, 1, 20); // February 20, 2024
    const startExperience = new Date(2018, 10, 1); // November 1, 2018

    const updateMetrics = () => {
      const now = new Date();

      // Update badges
      if (now >= startBadges) {
        const secondsPassed = Math.floor(
          (now.getTime() - startBadges.getTime()) / 1000
        );
        const currentHour = now.getUTCHours();
        const workingSeconds =
          currentHour >= 0 && currentHour < 24 ? secondsPassed : 0;
        const badgesProduced = Math.floor(workingSeconds / (3600 / 20)); // 3600 badges per hour
        const roundedBadges = Math.round((233300 + badgesProduced) / 100) * 100;
        setBadges(roundedBadges);
      }

      // Update experience
      if (now >= startExperience) {
        const yearsPassed = Math.floor(
          (now.getTime() - startExperience.getTime()) /
            (1000 * 60 * 60 * 24 * 365)
        );
        setExperience(yearsPassed);
      }
    };

    const intervalId = setInterval(updateMetrics, 1); // Update every second
    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);

  return (
    <div
      id="metrics"
      className="relative bg-slate-950 w-full flex flex-row py-16 px-20 items-center justify-center text-center text-5xl text-gray-200 font-text-xl-font-medium sm:text-4xl xs:px-6"
    >
      <div className="flex flex-row w-full max-w-[1340px] items-stretch justify-around lg:flex-col lg:gap-12">
        <div className="flex flex-col py-0 px-10 items-center justify-center">
          <div className="relative leading-[150%] font-extrabold text-gradient">
            350 +
          </div>
          <div className="relative leading-[150%] font-medium text-2xl sm:text-xl">
            {translations.metrics.badgesPerHour}
          </div>
        </div>
        <div className="flex flex-col py-0 px-10 items-center justify-center">
          <div className="relative leading-[150%] font-extrabold text-gradient">
            {badges
              .toLocaleString("en-US", { minimumFractionDigits: 0 })
              .replace(/,/g, " ")}{" "}
            +
          </div>
          <div className="relative leading-[150%] font-medium text-2xl sm:text-xl">
            {translations.metrics.badgesProduced}
          </div>
        </div>
        <div className="flex flex-col py-0 px-10 items-center justify-center">
          <div className="relative leading-[150%] font-extrabold text-gradient">
            {experience} +
          </div>
          <div className="relative leading-[150%] font-medium text-2xl sm:text-xl">
            {translations.metrics.yearsExperience}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;