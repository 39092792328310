import { FunctionComponent } from "react";
import badges from "../../assets/png/nexprim_badges.png";
import { useTranslation } from "../../TranslationContext";

const Hero: FunctionComponent = () => {
  const translations = useTranslation();

  return (
    <div
      id="hero"
      className="relative bg-white w-full overflow-hidden flex flex-col px-16 pt-0 pb-6 box-border items-center justify-center text-left text-6xl text-black font-text-xl-font-normal xl:text-5xl lg:text-4xl lg:leading-tight sm:px-12 xs:px-6"
    >
      <div className="w-full flex flex-row items-center justify-start max-w-[1400px] lg:flex-col md:gap-8">
        <div className="self-stretch flex-1 flex flex-col items-start justify-center max-w-[644px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[125%] font-extrabold text-gradient">
                <h1>{translations.hero.header1}</h1>
              </div>
              <div className="self-stretch relative leading-[125%] font-extrabold">
                <h1>{translations.hero.header2}</h1>
              </div>
            </div>
            <div className="self-stretch relative text-xl leading-[150%] text-slate-800 sm:text-lg">
              <h2 className="text-xl leading-[150%] text-slate-800 sm:text-lg">
                {translations.hero.description}
              </h2>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start lg:max-w-lg xs:max-w-sm">
          <img
            src={badges}
            alt={translations.hero.imageAlt}
            className="self-stretch"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
