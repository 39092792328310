import { FunctionComponent } from "react";

import GalleryComponent from "../ui/custom/gallery_component";

import badges_1 from "../../assets/webp/badges_1.webp";
import badges_2 from "../../assets/webp/badges_2.webp";
import badges_3 from "../../assets/webp/badges_3.webp";
import badges_4 from "../../assets/webp/badges_4.webp";
import badges_5 from "../../assets/webp/badges_5.webp";
import badges_6 from "../../assets/webp/badges_6.webp";

const Gallery: FunctionComponent = () => {
  const images = [
    { src: badges_1, alt: "Nexprim - Odznaky s potlačou" },
    { src: badges_2, alt: "Košice - Odznaky s potlačou" },
    { src: badges_3, alt: "Odznaky s vlastnou potlačou" },
    { src: badges_4, alt: "Odznaky s vlastnou potlačou" },
    { src: badges_5, alt: "Odznaky s vlastnou potlačou" },
    { src: badges_6, alt: "Odznaky s vlastnou potlačou" },
  ];

  return (
    <div
      id="gallery"
      className="relative bg-slate-950 w-full flex flex-col p-24 box-border items-start justify-start lg:p-20 md:p-10 sm:p-8 xs:p-8"
    >
      <div className="self-stretch flex flex-col items-center justify-center gap-12">
        <div className="self-stretch flex flex-row items-center justify-center gap-12 lg:gap-8">
          <GalleryComponent images={images} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;