import React, { FunctionComponent } from "react";

import Testimonial from "../ui/custom/testimonial";
import { useTranslation } from "../../TranslationContext";

const Testimonials: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="testimonials"
      className="relative bg-white w-full overflow-hidden flex flex-col py-28 box-border items-center justify-start text-center text-4xl text-slate-900 font-text-xl-font-normal md:py-20 2xl:px-20 lg:px-14 sm:px-10 xs:px-8 xs:py-14"
    >
      <div className="w-full flex flex-col items-center justify-start gap-16 max-w-[1280px] ">
        <div className="max-w-[42rem] flex flex-col items-start justify-start gap-[1rem]">
          <div className="self-stretch relative tracking-[-0.01em] leading-[125%] font-extrabold">
            {translations.testimonials.title}
          </div>
          <div className="flex self-stretch text-xl leading-[150%] text-slate-500 sm:text-lg">
            {translations.testimonials.subtitle}
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-8 text-left text-[1.13rem] grid grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
          <div className="grid grid-cols-1 gap-8 h-auto">
            <div className="w-full h-auto">
              <Testimonial
                header={translations.testimonials.items[0].header}
                content={translations.testimonials.items[0].content}
                name={translations.testimonials.items[0].name}
              />
            </div>
            <div className="w-full h-auto">
              <Testimonial
                header={translations.testimonials.items[1].header}
                content={translations.testimonials.items[1].content}
                name={translations.testimonials.items[1].name}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:hidden">
            <div className="w-full">
              <Testimonial
                header={translations.testimonials.items[2].header}
                content={translations.testimonials.items[2].content}
                name={translations.testimonials.items[2].name}
              />
            </div>
            <div className="w-full">
              <Testimonial
                header={translations.testimonials.items[3].header}
                content={translations.testimonials.items[3].content}
                name={translations.testimonials.items[3].name}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 lg:hidden">
            <div className="w-full">
              <Testimonial
                header={translations.testimonials.items[4].header}
                content={translations.testimonials.items[4].content}
                name={translations.testimonials.items[4].name}
              />
            </div>
            <div className="w-full">
              <Testimonial
                header={translations.testimonials.items[5].header}
                content={translations.testimonials.items[5].content}
                name={translations.testimonials.items[5].name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
