import React, { FunctionComponent } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import OrderIcon from "../../assets/custom_icons/OrderIcon";
import QualityIcon from "../../assets/custom_icons/QualityIcon";
import DeliveryIcon from "../../assets/custom_icons/DeliveryIcon";
import PaymentIcon from "../../assets/custom_icons/PaymentIcon";
import RefundIcon from "../../assets/custom_icons/RefundIcon";
import GraphicsIcon from "../../assets/custom_icons/GraphicsIcon";
import { useTranslation } from "../../TranslationContext";

interface FAQProps {
  isTitleVisible?: boolean;
}

const FAQ: FunctionComponent<FAQProps> = ({ isTitleVisible = true }) => {
  const translations = useTranslation();

  return (
    <div
      id="faq"
      className="relative bg-white w-full overflow-hidden flex flex-col py-24 px-20 box-border items-center justify-start text-center text-4xl text-livid-900 sm:px-12 xs:px-6 xs:py-16 "
    >
      <div className="max-w-[700px] w-full flex flex-col items-center justify-start gap-12 no-underline hover:no-underline">
        {isTitleVisible && (
          <div className="self-stretch flex flex-col items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[125%] font-extrabold flex items-center justify-center w-full ">
              {translations.faq.title}
            </div>
          </div>
        )}
        <div className="w-full">
          <Accordion type="single" collapsible>
            <AccordionItem className="border-0" value="item-1-1">
              <AccordionTrigger variant="bold" icon={OrderIcon}>
                {translations.faq.order_production}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-1-2">
                    <AccordionTrigger>
                      {translations.faq.how_long_production}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.production_time}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-3">
                    <AccordionTrigger>
                      {translations.faq.minimum_order}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.minimum_order_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-4">
                    <AccordionTrigger>
                      {translations.faq.modify_cancel_order}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.modify_cancel_order_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-5">
                    <AccordionTrigger>
                      {translations.faq.sample_badge}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.sample_badge_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-6">
                    <AccordionTrigger>
                      {translations.faq.different_badges}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.different_badges_content}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-2-1">
              <AccordionTrigger variant="bold" icon={QualityIcon}>
                {translations.faq.badge_quality}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-2-2">
                    <AccordionTrigger>
                      {translations.faq.production_process}
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>{translations.faq.production_process_content1}</p>
                      <p>{translations.faq.production_process_content2}</p>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-3">
                    <AccordionTrigger>
                      {translations.faq.materials}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.materials_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-4">
                    <AccordionTrigger>
                      {translations.faq.lifespan}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.lifespan_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-5">
                    <AccordionTrigger>
                      {translations.faq.uv_resistance}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.uv_resistance_content}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-3-1">
              <AccordionTrigger variant="bold" icon={GraphicsIcon}>
                {translations.faq.badge_graphics}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-3-2">
                    <AccordionTrigger>
                      {translations.faq.graphics_modification}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.graphics_modification_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-3">
                    <AccordionTrigger>
                      {translations.faq.upload_issues}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.upload_issues_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-4">
                    <AccordionTrigger>
                      {translations.faq.file_format}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.file_format_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-5">
                    <AccordionTrigger>
                      {translations.faq.design_consultation}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.design_consultation_content}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-4-1">
              <AccordionTrigger variant="bold" icon={DeliveryIcon}>
                {translations.faq.delivery}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-4-2">
                    <AccordionTrigger>
                      {translations.faq.delivery_cost}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery_cost_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-3">
                    <AccordionTrigger>
                      {translations.faq.delivery_time}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery_time_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-4">
                    <AccordionTrigger>
                      {translations.faq.express_delivery}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.express_delivery_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-5">
                    <AccordionTrigger>
                      {translations.faq.delivery_countries}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery_countries_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-6">
                    <AccordionTrigger>
                      {translations.faq.personal_pickup}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.personal_pickup_content}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-5-1">
              <AccordionTrigger variant="bold" icon={PaymentIcon}>
                {translations.faq.payments_prices}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-5-2">
                    <AccordionTrigger>
                      {translations.faq.payment_methods}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.payment_methods_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-6">
                    <AccordionTrigger>
                      {translations.faq.invoice_payment}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.invoice_payment_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-3">
                    <AccordionTrigger>
                      {translations.faq.bulk_discounts}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.bulk_discounts_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-4">
                    <AccordionTrigger>
                      {translations.faq.loyalty_discounts}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.loyalty_discounts_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-5">
                    <AccordionTrigger>
                      {translations.faq.prices_vat}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.prices_vat_content}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-6-1">
              <AccordionTrigger variant="bold" icon={RefundIcon}>
                {translations.faq.returns_warranty}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-6-2">
                    <AccordionTrigger>
                      {translations.faq.return_badges}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.return_badges_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-6-3">
                    <AccordionTrigger>
                      {translations.faq.badge_warranty}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.badge_warranty_content}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-6-4">
                    <AccordionTrigger>
                      {translations.faq.damaged_badge}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.damaged_badge_content}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
